import React from "react"
import {
  Container,
  List,
  Header,
  Icon,
  Breadcrumb,
  Message,
} from "semantic-ui-react"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SelfCheckButton from "../components/SelfCheckButton"

const TextbookPageTemplate = ({ data }) => {
  const { nodes } = data.allMdx
  const node = nodes[0]
  const subheaderElems = [node.frontmatter.subtitle, node.frontmatter.edition, node.frontmatter.language]
    .map(e => e ? [e] : [])
    .flat()
    .join(", ")
  return (
    <Layout>
      <SEO title={`${node.frontmatter.pl} - ${node.parent.name}`} />
      <Container text style={{ marginTop: "100px", marginBottom: "100px" }}>
        <Breadcrumb>
          <Breadcrumb.Section link as={Link} to="/">
            Home
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section link as={Link} to="/textbooks">
            Textbooks
          </Breadcrumb.Section>
          {!node.frontmatter.pl ? "" :
            <>
              <Breadcrumb.Divider icon="right chevron" />
              <Breadcrumb.Section>{node.frontmatter.pl}</Breadcrumb.Section>
            </>
          }
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section active>{node.parent.name}</Breadcrumb.Section>
        </Breadcrumb>

        <Header as="h1" dividing>
          <Icon name="book" />
          <Header.Content>
            <Header.Subheader>
              {node.frontmatter.author}
            </Header.Subheader>
            {node.frontmatter.title}
            <Header.Subheader>
              {subheaderElems ? <>{subheaderElems}<br/></> : ""}
            </Header.Subheader>
          </Header.Content>
        </Header>

        <MDXProvider components>
          <MDXRenderer>{node.body}</MDXRenderer>
        </MDXProvider>

        <Message icon info>
          <Icon name="info circle" />
          <Message.Content>
            <Message.Header>Find this Book</Message.Header>
            <p>
              <a href={node.frontmatter.home}>{node.frontmatter.home}</a>
            </p>
          </Message.Content>
        </Message>

        {!node.frontmatter.sections ? "" :
          <>
            <div style={{ marginTop: "1em" }}>
              <p>
                Our {node.frontmatter.pl} misconceptions relate to the following
                sections of that textbook:
              </p>
            </div>

            <List selection verticalAlign="middle">
              {node.frontmatter.sections.map((section, index) => (
                <List.Item
                  as={Link}
                  to={`/textbooks/${node.parent.name}/${section.section}`}
                  key={index}
                >
                  <List.Icon name="book" verticalAlign="middle" />
                  <List.Content>
                    <List.Content floated='right'>
                      <SelfCheckButton textbook={`${node.parent.name}/${section.section}`}/>
                    </List.Content>
                    {section.section}
                    <List.Header>
                      {/* color this like a link, without producing a nested a element */}
                      <span style={{ color: "#4183c4" }}>{section.title}</span>
                    </List.Header>
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </>
        }
      </Container>
    </Layout>
  )
}

export default TextbookPageTemplate

export const query = graphql`
  query($glob: String!) {
    allMdx(filter: { fileAbsolutePath: { glob: $glob } }) {
      nodes {
        body
        frontmatter {
          title
          subtitle
          edition
          pl
          language
          home
          baseUrl
          author
          sections {
            section
            title
            url
          }
        }
        parent {
          ... on File {
            name
          }
        }
      }
    }
  }
`
